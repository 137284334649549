import React, { $tools, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import './sass/topNav.scss'
import { connect } from "react-redux";

function TopNav({ userInfo }) {
    const [ topNavConW, setTopNavConW ] = useState(0);
    const [ navData ] = useState([
        // { title: "炼金配方(精确)", path: "/formulaSquare" },
        { title: '帮助', path: '/help' },
        // { title: 'Roll房', path: '/rollRoom' },
        // { title: '交易佣金奖励', path: '/promoted' },
        // { title: 'MOE治理与激励和白皮书', path: '/moeGo' },
    ])
    
    useEffect(_ => {
        let _w = $tools.getStyle(document.querySelector('.nav-container'), 'width')
        setTopNavConW(50 + Number(_w.replace('px', '')))
    }, [])
    return (
        <div className="top-nav">
            <div className="top-nav-box" style={ { width: topNavConW + 'px' } }>
                { navData.map((v, i) =>
                    <NavLink activeClassName="current" className="top-nav-item" key={ i }
                             to={ v.path === '/formulaSquare' ? ! userInfo.uid ? '/formulaSquare#replacingAnalog' : '/formulaSquare' : v.path }>{ v.title }</NavLink>
                ) }
                {/*<a href="/rollRoom" onClick={ _ => {*/ }
                {/*    window.localStorage.setItem("rollRoomNav", "moe")*/ }
                {/*} } className="top-nav-item">早鸟起飞活动</a>*/ }
            </div>
        </div>
    )
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(TopNav);
