const csgo = [
    {
        "label": "饰品种类",
        "value": "itemType",
        "children": [
            {
                "label": "匕首",
                "value": "CSGO_Type_Knife",
                "children": [
                    { "label": "折叠刀", "value": "weapon_knife_flip" },
                    { "label": "穿肠刀", "value": "weapon_knife_gut" },
                    { "label": "刺刀", "value": "weapon_bayonet" },
                    { "label": "M9刺刀", "value": "weapon_knife_m9_bayonet" },
                    { "label": "爪子刀", "value": "weapon_knife_karambit" },
                    { "label": "蝴蝶刀", "value": "weapon_knife_butterfly" },
                    { "label": "弯刀", "value": "weapon_knife_falchion" },
                    { "label": "暗影双匕", "value": "weapon_knife_push" },
                    { "label": "鲍伊猎刀", "value": "weapon_knife_survival_bowie" },
                    { "label": "短剑", "value": "weapon_knife_stiletto" },
                    { "label": "熊刀", "value": "weapon_knife_ursus" },
                    { "label": "折刀", "value": "weapon_knife_gypsy_jackknife" },
                    { "label": "锯齿爪刀", "value": "weapon_knife_widowmaker" },
                    { "label": "海豹短刀", "value": "weapon_knife_css" },
                    { "label": "骷髅匕首", "value": "weapon_knife_skeleton" },
                    { "label": "系绳匕首", "value": "weapon_knife_cord" },
                    { "label": "求生匕首", "value": "weapon_knife_canis" },
                    { "label": "流浪者匕首", "value": "weapon_knife_outdoor" },
                    { "label": "猎杀者匕首", "value": "weapon_knife_tactical" }
                ]
            },
            {
                "label": "手枪",
                "value": "CSGO_Type_Pistol",
                "children": [
                    { "label": "P2000", "value": "weapon_hkp2000" },
                    { "label": "USP 消音版", "value": "weapon_usp_silencer" },
                    { "label": "格洛克 18 型", "value": "weapon_glock" },
                    { "label": "P250", "value": "weapon_p250" },
                    { "label": "FN57", "value": "weapon_fiveseven" },
                    { "label": "CZ75 自动手枪", "value": "weapon_cz75a" },
                    { "label": "沙漠之鹰", "value": "weapon_deagle" },
                    { "label": "Tec-9", "value": "weapon_tec9" },
                    { "label": "R8 左轮手枪", "value": "weapon_revolver" },
                    { "label": "双持贝瑞塔", "value": "weapon_elite" }
                ]
            },
            {
                "label": "步枪",
                "value": "CSGO_Type_Rifle",
                "children": [
                    {
                        "label": "法玛斯",
                        "value": "weapon_famas"
                    },
                    {
                        "label": "加利尔 AR",
                        "value": "weapon_galilar"
                    },
                    {
                        "label": "M4A4",
                        "value": "weapon_m4a1"
                    },
                    {
                        "label": "M4A1 消音型",
                        "value": "weapon_m4a1_silencer"
                    },
                    {
                        "label": "AK-47",
                        "value": "weapon_ak47"
                    },
                    {
                        "label": "AWP",
                        "value": "weapon_awp"
                    },
                    {
                        "label": "AUG",
                        "value": "weapon_aug"
                    },
                    {
                        "label": "SG 553",
                        "value": "weapon_sg556"
                    },
                    {
                        "label": "SSG 08",
                        "value": "weapon_ssg08"
                    },
                    {
                        "label": "SCAR-20",
                        "value": "weapon_scar20"
                    },
                    {
                        "label": "G3SG1",
                        "value": "weapon_g3sg1"
                    }
                ]
            },
            {
                "label": "微型冲锋枪",
                "value": "CSGO_Type_SMG",
                "children": [
                    { "label": "MAC-10", "value": "weapon_mac10" },
                    { "label": "MP9", "value": "weapon_mp9" },
                    { "label": "MP7", "value": "weapon_mp7" },
                    { "label": "UMP-45", "value": "weapon_ump45" },
                    { "label": "PP-野牛", "value": "weapon_bizon" },
                    { "label": "P90", "value": "weapon_p90" },
                    { "label": "MP5-SD", "value": "weapon_mp5sd" }
                ]
            },
            {
                "label": "散弹枪",
                "value": "CSGO_Type_Shotgun",
                "children": [
                    { "label": "新星", "value": "weapon_nova" },
                    { "label": "XM1014", "value": "weapon_xm1014" },
                    { "label": "MAG-7", "value": "weapon_mag7" },
                    { "label": "截短霰弹枪", "value": "weapon_sawedoff" }
                ]
            },
            {
                "label": "机枪",
                "value": "CSGO_Type_Machinegun",
                "children": [
                    { "label": "M249", "value": "weapon_m249" },
                    { "label": "内格夫", "value": "weapon_negev" }
                ]
            },
            {
                "label": "手套",
                "value": "Type_Hands",
                "children": [
                    { "label": "血猎手套", "value": "Bloodhound Gloves" },
                    { "label": "驾驶手套", "value": "Driver Gloves" },
                    { "label": "裹手", "value": "Hand Wraps" },
                    { "label": "摩托手套", "value": "Moto Gloves" },
                    { "label": "专业手套", "value": "Specialist Gloves" },
                    { "label": "运动手套", "value": "Sport Gloves" },
                    { "label": "九头蛇手套", "value": "Hydra Gloves" },
                    { "label": "狂牙手套", "value": "Broken Fang Gloves" }
                ]
            },
            {
                "label": "印花",
                "value": "CSGO_Tool_Sticker",
                "children": [
                    // {
                    //   "label": "不限",
                    //   "value": "unlimited"
                    // },
                    {
                        "label": "间谍印花",
                        "value": "crate_sticker_pack_community2022_capsule_lootlist"
                    },
                    {
                        "label": "2022年里约",
                        "value": "pack_rio2022"
                    },
                    {
                        "label": "CSGO10周年",
                        "value": "crate_sticker_pack_csgo10_capsule_lootlist"
                    },
                    {
                        "label": "2022年安特卫普",
                        "value": "pack_antwerp2022"
                    },
                    {
                        "label": "作战室印花",
                        "value": "crate_sticker_pack_spring2022_capsule_lootlist"
                    },
                    {
                        "label": "战地风云2042",
                        "value": "crate_sticker_pack_bf2042_capsule_lootlist"
                    },
                    {
                        "label": "2021年斯德哥尔摩",
                        "value": "pack_stockh2021"
                    },
                    {
                        "label": "激流冲浪店",
                        "value": "crate_sticker_pack_riptide_surfshop_lootlist"
                    },
                    {
                        "label": "激流大行动",
                        "value": "crate_sticker_pack_op_riptide_capsule_lootlist"
                    },
                    {
                        "label": "2021社区印花",
                        "value": "crate_sticker_pack_community2021_capsule_lootlist"
                    },
                    {
                        "label": "魔性探员",
                        "value": "crate_sticker_pack_poorly_drawn_lootlist"
                    },
                    {
                        "label": "2020 RMR",
                        "value": "pack_rmr2020"
                    },
                    {
                        "label": "狂牙大行动",
                        "value": "crate_sticker_pack_broken_fang_lootlist"
                    },
                    {
                        "label": "压枪印花",
                        "value": "crate_sticker_pack_recoil_lootlist"
                    },
                    {
                        "label": "战锤40k印花",
                        "value": "crate_sticker_pack_warhammer_lootlist"
                    },
                    {
                        "label": "半衰期：爱莉克斯",
                        "value": "crate_sticker_pack_hlalyx_capsule_lootlist"
                    },
                    {
                        "label": "光环",
                        "value": "crate_sticker_pack_halo_capsule_lootlist"
                    },
                    {
                        "label": "裂网大行动",
                        "value": "crate_sticker_pack_shattered_web_lootlist"
                    },
                    {
                        "label": "CS20周年",
                        "value": "crate_sticker_pack_cs20_capsule_lootlist"
                    },
                    {
                        "label": "2019年柏林",
                        "value": "pack_berlin2019"
                    },
                    {
                        "label": "小鸡",
                        "value": "crate_sticker_pack_chicken_capsule_lootlist"
                    },
                    {
                        "label": "猛兽",
                        "value": "crate_sticker_pack_feral_predators_capsule_lootlist"
                    },
                    {
                        "label": "2019年卡托维兹",
                        "value": "pack_katowice2019"
                    },
                    {
                        "label": "段位印花",
                        "value": "crate_sticker_pack_skillgroup_capsule_lootlist"
                    },
                    {
                        "label": "2018年伦敦",
                        "value": "pack_london2018"
                    },
                    {
                        "label": "2018年波士顿",
                        "value": "pack_boston2018"
                    },
                    {
                        "label": "2017年克拉科夫",
                        "value": "pack_krakow2017"
                    },
                    {
                        "label": "2017年亚特兰大",
                        "value": "pack_atlanta2017"
                    },
                    {
                        "label": "2016年科隆",
                        "value": "pack_cologne2016"
                    },
                    {
                        "label": "2016年哥伦布",
                        "value": "pack_columbus2016"
                    },
                    {
                        "label": "2015年DreamHack",
                        "value": "pack_cluj2015"
                    },
                    {
                        "label": "2015年科隆",
                        "value": "pack_eslcologne2015"
                    },
                    {
                        "label": "2015年卡托维兹",
                        "value": "pack_eslkatowice2015"
                    },
                    {
                        "label": "2014年DreamHack",
                        "value": "pack_dhw2014"
                    },
                    {
                        "label": "2014年科隆",
                        "value": "pack_cologne2014"
                    },
                    {
                        "label": "2014年卡托维兹",
                        "value": "pack_kat2014"
                    },
                    {
                        "label": "2018年社区印花",
                        "value": "crate_sticker_pack_comm2018_01_capsule_lootlist"
                    },
                    {
                        "label": "1号印花",
                        "value": "crate_sticker_pack01"
                    },
                    {
                        "label": "2号印花",
                        "value": "crate_sticker_pack02"
                    },
                    {
                        "label": "Enfu印花",
                        "value": "crate_sticker_pack_enfu_capsule_lootlist"
                    },
                    {
                        "label": "点亮中国1号",
                        "value": "crate_sticker_pack_illuminate_capsule_01_lootlist"
                    },
                    {
                        "label": "点亮中国2号",
                        "value": "crate_sticker_pack_illuminate_capsule_02_lootlist"
                    },
                    {
                        "label": "1号社区印花",
                        "value": "crate_sticker_pack_community01"
                    },
                    {
                        "label": "动物寓言印花",
                        "value": "crate_sticker_pack_bestiary_capsule_lootlist"
                    },
                    {
                        "label": "Slid3印花",
                        "value": "crate_sticker_pack_slid3_capsule_lootlist"
                    },
                    {
                        "label": "糖果脸谱印花",
                        "value": "crate_sticker_pack_sugarface_capsule_lootlist"
                    },
                    {
                        "label": "海报女郎印花",
                        "value": "crate_sticker_pack_pinups_capsule_lootlist"
                    },
                    {
                        "label": "团队定位印花",
                        "value": "crate_sticker_pack_team_roles_capsule_lootlist"
                    },
                    {
                        "label": "其他印花",
                        "value": "crate_sticker_other"
                    }
                ]
            },
            {
                "label": "探员",
                "value": "Type_CustomPlayer",
                "children": [
                    {
                        "label": "反恐精英",
                        "value": "agent_team_ct"
                    },
                    {
                        "label": "恐怖分子",
                        "value": "agent_team_terrorist"
                    }
                ]
            },
            {
                "label": "其他",
                "value": "other",
                "children": [
                    {
                        "label": "工具",
                        "value": "CSGO_Type_Tool"
                    },
                    {
                        "label": "涂鸦",
                        "value": "CSGO_Type_Spray"
                    },
                    {
                        "label": "收藏品",
                        "value": "CSGO_Type_Collectible"
                    },
                    {
                        "label": "通行证",
                        "value": "CSGO_Type_Ticket"
                    },
                    {
                        "label": "礼物",
                        "value": "CSGO_Tool_GiftTag"
                    },
                    {
                        "label": "音乐盒",
                        "value": "CSGO_Type_MusicKit"
                    },
                    {
                        "label": "武器箱",
                        "value": "CSGO_Type_WeaponCase"
                    },
                    {
                        "label": "布章",
                        "value": "CSGO_Tool_Patch"
                    },
                    {
                        "label": "名称标签",
                        "value": "CSGO_Tool_Name_TagTag"
                    }
                ]
            }
        ]
    },
    {
        "label": "品质",
        "value": "rarity",
        "children": [
            {
                "label": "不限",
                "value": "unlimited"
            },
            {
                "label": "违禁",
                "value": "Rarity_Contraband",
                "color": "#E4AE39"
            },
            {
                "label": "隐秘",
                "value": "Rarity_Ancient_Weapon",
                "color": "#EB4B4B"
            },
            {
                "label": "保密",
                "value": "Rarity_Legendary_Weapon",
                "color": "#D32CE6"
            },
            {
                "label": "受限",
                "value": "Rarity_Mythical_Weapon",
                "color": "#8847FF"
            },
            {
                "label": "军规级",
                "value": "Rarity_Rare_Weapon",
                "color": "#4B69FF"
            },
            {
                "label": "工业级",
                "value": "Rarity_Uncommon_Weapon",
                "color": "#5E98D9"
            },
            {
                "label": "消费级",
                "value": "Rarity_Common_Weapon",
                "color": "#B0C3D9"
            },
            {
                "label": "非凡",
                "value": "Rarity_Ancient",
                "color": "#eb4b4b"
            },
            {
                "label": "卓越",
                "value": "Rarity_Mythical",
                "color": "#8847ff"
            },
            {
                "label": "奇异",
                "value": "Rarity_Legendary",
                "color": "#d32ce6"
            },
            {
                "label": "高级",
                "value": "Rarity_Rare",
                "color": "#4b69ff"
            },
            {
                "label": "普通级",
                "value": "Rarity_Common",
                "color": "#B0C3D9"
            },
            
            {
                "label": "探员品质",
                "value": "Type_CustomPlayer",
                "children": [
                    {
                        "label": "大师",
                        "value": "Rarity_Ancient_Character",
                        "color": "#EB4B4B"
                    },
                    {
                        "label": "卓越",
                        "value": "Rarity_Mythical_Character",
                        "color": "#8847ff"
                    },
                    {
                        "label": "非凡",
                        "value": "Rarity_Legendary_Character",
                        "color": "#d32ce6"
                    },
                    {
                        "label": "高级",
                        "value": "Rarity_Rare_Character",
                        "color": "#4b69ff"
                    }
                ]
            }
        ]
    },
    {
        "label": "外观",
        "value": "exterior",
        "children": [
            {
                "label": "不限",
                "value": "unlimited"
            },
            {
                "label": "崭新出厂",
                "value": "WearCategory0",
                "color": "green",
                "range": [
                    { label: '不限', value: 'unlimited' },
                    { label: '0.00-0.01', value: '[0.00, 0.01]' },
                    { label: '0.01-0.02', value: '[0.01, 0.02]' },
                    { label: '0.02-0.03', value: '[0.02, 0.03]' },
                    { label: '0.03-0.04', value: '[0.03, 0.04]' },
                    { label: '0.04-0.07', value: '[0.04, 0.07]' },
                    { label: '自定义', value: 'customize' }
                ]
            },
            {
                "label": "略有磨损",
                "value": "WearCategory1",
                "color": "#5cb85c",
                "range": [
                    { label: '不限', value: 'unlimited' },
                    { label: '0.07-0.08', value: '[0.07, 0.08]' },
                    { label: '0.08-0.09', value: '[0.08, 0.09]' },
                    { label: '0.09-0.10', value: '[0.09, 0.10]' },
                    { label: '0.10-0.11', value: '[0.10, 0.11]' },
                    { label: '0.11-0.15', value: '[0.11, 0.15]' },
                    { label: '自定义', value: 'customize' }
                ]
            },
            {
                "label": "久经沙场",
                "value": "WearCategory2",
                "color": "#f0ad4e",
                "range": [
                    { label: '不限', value: 'unlimited' },
                    { label: '0.15-0.18', value: '[0.15, 0.18]' },
                    { label: '0.18-0.21', value: '[0.18, 0.21]' },
                    { label: '0.21-0.24', value: '[0.21, 0.24]' },
                    { label: '0.24-0.27', value: '[0.24, 0.27]' },
                    { label: '0.27-0.38', value: '[0.27, 0.38]' },
                    { label: '自定义', value: 'customize' }
                ]
            },
            {
                "label": "破损不堪",
                "value": "WearCategory3",
                "color": "#d9534f",
                "range": [
                    { label: '不限', value: 'unlimited' },
                    { label: '0.38-0.39', value: '[0.38, 0.39]' },
                    { label: '0.39-0.40', value: '[0.39, 0.40]' },
                    { label: '0.40-0.41', value: '[0.40, 0.41]' },
                    { label: '0.41-0.42', value: '[0.41, 0.42]' },
                    { label: '0.42-0.45', value: '[0.42, 0.45]' },
                    { label: '自定义', value: 'customize' }
                ]
            },
            {
                "label": "战痕累累",
                "value": "WearCategory4",
                "color": "#993A38",
                "range": [
                    { label: '不限', value: 'unlimited' },
                    { label: '0.45-0.50', value: '[0.45, 0.50]' },
                    { label: '0.50-0.63', value: '[0.50, 0.63]' },
                    { label: '0.63-0.76', value: '[0.63, 0.76]' },
                    { label: '0.76-0.90', value: '[0.76, 0.90]' },
                    { label: '0.90-1.00', value: '[0.90, 1.00]' },
                    { label: '自定义', value: 'customize' }
                ]
            },
            {
                "label": "无涂装",
                "value": "WearCategoryNA",
                "color": "#F58C3E"
            }
        ]
    },
    {
        "label": "类别",
        "value": "quality",
        "children": [
            {
                "label": "不限",
                "value": "unlimited"
            },
            {
                "label": "普通",
                "value": "normal",
                "color": "#b0c3d9"
            },
            {
                "label": "StatTrak™",
                "value": "strange",
                "color": "#CF6A32"
            },
            {
                "label": "★",
                "value": "unusual",
                "color": "#8650ac"
            },
            {
                "label": "★ StatTrak™",
                "value": "unusual_strange",
                "color": "#CF6A32"
            },
            {
                "label": "纪念品",
                "value": "tournament",
                "color": "#FFD700"
            }
        ]
    },
    // {
    //     "label": "精选分类",
    //     "value": "concentration",
    //     "children": [
    //         {
    //             "label": "不限",
    //             "value": "unlimited"
    //         },
    //         {
    //             "label": "全满贴纸",
    //             "value": "fullSticker"
    //         },
    //         {
    //             "label": "金贴专区",
    //             "value": "goldStickers"
    //         },
    //         {
    //             "label": "卡托专区",
    //             "value": "cato"
    //         },
    //         {
    //             "label": "四联贴纸",
    //             "value": "quadrupleSticker"
    //         }
    //     ]
    // },
    {
        "label": "收藏品",
        "value": "collection",
        "children": [
            {
                "label": "不限",
                "value": "unlimited"
            },
            {
                "label": "武器箱收藏品",
                "value": "case",
                "children": [
                    {
                        "label": "千瓦",
                        "value": "set_community_33",
                    },
                    {
                        "label": "变革",
                        "value": "set_community_32",
                    },
                    {
                        "label": "反冲",
                        "value": "set_community_31"
                    },
                    {
                        "label": "梦魇",
                        "value": "set_community_30"
                    },
                    {
                        "label": "激流大行动",
                        "value": "set_community_29"
                    },
                    {
                        "label": "蛇噬",
                        "value": "set_community_28"
                    },
                    {
                        "label": "狂牙大行动",
                        "value": "set_community_27"
                    },
                    {
                        "label": "裂空",
                        "value": "set_community_26"
                    },
                    {
                        "label": "棱彩2号",
                        "value": "set_community_25"
                    },
                    {
                        "label": "裂网大行动",
                        "value": "set_community_23"
                    },
                    {
                        "label": "CS20周年",
                        "value": "set_community_24"
                    },
                    {
                        "label": "棱彩",
                        "value": "set_community_22"
                    },
                    {
                        "label": "头号特训",
                        "value": "set_community_21"
                    },
                    {
                        "label": "地平线",
                        "value": "set_community_20"
                    },
                    {
                        "label": "命悬一线",
                        "value": "set_community_19"
                    },
                    {
                        "label": "光谱2号",
                        "value": "set_community_18"
                    },
                    {
                        "label": "九头蛇大行动",
                        "value": "set_community_17"
                    },
                    {
                        "label": "光谱",
                        "value": "set_community_16"
                    },
                    {
                        "label": "手套",
                        "value": "set_community_15"
                    },
                    {
                        "label": "伽玛2号",
                        "value": "set_gamma_2"
                    },
                    {
                        "label": "伽玛",
                        "value": "set_community_13"
                    },
                    {
                        "label": "幻彩3号",
                        "value": "set_community_12"
                    },
                    {
                        "label": "野火大行动",
                        "value": "set_community_11"
                    },
                    {
                        "label": "左轮",
                        "value": "set_community_10"
                    },
                    {
                        "label": "暗影",
                        "value": "set_community_9"
                    },
                    {
                        "label": "弯曲猎手",
                        "value": "set_community_8"
                    },
                    {
                        "label": "幻彩2号",
                        "value": "set_community_7"
                    },
                    {
                        "label": "幻彩",
                        "value": "set_community_6"
                    },
                    {
                        "label": "先锋大行动",
                        "value": "set_community_5"
                    },
                    {
                        "label": "电竞2014夏季",
                        "value": "set_esports_iii"
                    },
                    {
                        "label": "突围大行动",
                        "value": "set_community_4"
                    },
                    {
                        "label": "猎杀者",
                        "value": "set_community_3"
                    },
                    {
                        "label": "凤凰大行动",
                        "value": "set_community_2"
                    },
                    {
                        "label": "军火交易3号",
                        "value": "set_weapons_iii"
                    },
                    {
                        "label": "冬季攻势",
                        "value": "set_community_1"
                    },
                    {
                        "label": "军火交易2号",
                        "value": "set_weapons_ii"
                    },
                    {
                        "label": "电竞2013冬季",
                        "value": "set_esports_ii"
                    },
                    {
                        "label": "英勇大行动",
                        "value": "set_bravo_i"
                    },
                    {
                        "label": "电竞2013",
                        "value": "set_esports"
                    },
                    {
                        "label": "军火交易",
                        "value": "set_weapons_i"
                    }
                ]
            },
            {
                "label": "地图收藏品",
                "value": "collection",
                "children": [
                    {
                        "label": "2021殒命大厦",
                        "value": "set_vertigo_2021"
                    },
                    {
                        "label": "2021炙热沙城II",
                        "value": "set_dust_2_2021"
                    },
                    {
                        "label": "2021荒漠迷城",
                        "value": "set_mirage_2021"
                    },
                    {
                        "label": "2021列车停放站",
                        "value": "set_train_2021"
                    },
                    {
                        "label": "远古",
                        "value": "set_op10_ancient"
                    },
                    {
                        "label": "浩劫",
                        "value": "set_op10_t"
                    },
                    {
                        "label": "控制",
                        "value": "set_op10_ct"
                    },
                    {
                        "label": "挪威人",
                        "value": "set_norse"
                    },
                    {
                        "label": "运河水城",
                        "value": "set_canals"
                    },
                    {
                        "label": "圣马克镇",
                        "value": "set_stmarc"
                    },
                    {
                        "label": "2018炼狱小镇",
                        "value": "set_inferno_2"
                    },
                    {
                        "label": "2018核子危机",
                        "value": "set_nuke_2"
                    },
                    {
                        "label": "殒命大厦",
                        "value": "set_vertigo"
                    },
                    {
                        "label": "列车停放站",
                        "value": "set_train"
                    },
                    {
                        "label": "安全处所",
                        "value": "set_safehouse"
                    },
                    {
                        "label": "旭日",
                        "value": "set_kimono"
                    },
                    {
                        "label": "死亡游乐园",
                        "value": "set_overpass"
                    },
                    {
                        "label": "办公室",
                        "value": "set_office"
                    },
                    {
                        "label": "核子危机",
                        "value": "set_nuke"
                    },
                    {
                        "label": "荒漠迷城",
                        "value": "set_mirage"
                    },
                    {
                        "label": "佣兵训练营",
                        "value": "set_militia"
                    },
                    {
                        "label": "湖畔激战",
                        "value": "set_lake"
                    },
                    {
                        "label": "意大利小镇",
                        "value": "set_italy"
                    },
                    {
                        "label": "炼狱小镇",
                        "value": "set_inferno"
                    },
                    {
                        "label": "神魔",
                        "value": "set_gods_and_monsters"
                    },
                    {
                        "label": "炙热沙城II",
                        "value": "set_dust_2"
                    },
                    {
                        "label": "炙热沙城",
                        "value": "set_dust"
                    },
                    {
                        "label": "古堡激战",
                        "value": "set_cobblestone"
                    },
                    {
                        "label": "解体厂",
                        "value": "set_chopshop"
                    },
                    {
                        "label": "死城之谜",
                        "value": "set_cache"
                    },
                    {
                        "label": "金库危机",
                        "value": "set_bank"
                    },
                    {
                        "label": "行李仓库",
                        "value": "set_baggage"
                    },
                    {
                        "label": "雨林遗迹",
                        "value": "set_aztec"
                    },
                    {
                        "label": "仓库突击",
                        "value": "set_assault"
                    },
                    {
                        "label": "无畏",
                        "value": "set_bravo_ii"
                    }
                ]
            }
        ]
    },
    // {
    //     "label": "数据榜",
    //     "value": "dataRanking",
    //     "children": [
    //         {
    //             "label": "不限",
    //             "value": "unlimited"
    //         },
    //         {
    //             "label": "24H饰品涨跌",
    //             "value": 1
    //         },
    //         {
    //             "label": "1天饰品涨跌",
    //             "value": 2
    //         },
    //         {
    //             "label": "最近30天饰品涨跌",
    //             "value": 3
    //         },
    //         {
    //             "label": "最近60天饰品涨跌",
    //             "value": 4
    //         },
    //         {
    //             "label": "最近180天饰品涨跌",
    //             "value": 5
    //         },
    //         {
    //             "label": "历史饰品涨跌",
    //             "value": 6
    //         }
    //     ]
    // }
]

const dota2 = [
    {
        label: "英雄",
        value: "hero",
        children: [
            {
                label: "力量",
                value: "power",
                children: [
                    {
                        label: "亚巴顿",
                        value: "npc_dota_hero_abaddon",
                        heroAvatar: require("@/images/hero/power/abaddon.png")
                    },
                    {
                        label: "孽主",
                        value: "npc_dota_hero_abyssal_underlord",
                        heroAvatar: require("@/images/hero/power/abyssal_underlord.png")
                    },
                    {
                        label: "炼金术士",
                        value: "npc_dota_hero_alchemist",
                        heroAvatar: require("@/images/hero/power/alchemist.png")
                    },
                    { label: "斧王", value: "npc_dota_hero_axe", heroAvatar: require("@/images/hero/power/axe.png") },
                    {
                        label: "兽王",
                        value: "npc_dota_hero_beastmaster",
                        heroAvatar: require("@/images/hero/power/beastmaster.png")
                    },
                    {
                        label: "酒仙",
                        value: "npc_dota_hero_brewmaster",
                        heroAvatar: require("@/images/hero/power/brewmaster.png")
                    },
                    {
                        label: "钢背兽",
                        value: "npc_dota_hero_bristleback",
                        heroAvatar: require("@/images/hero/power/bristleback.png")
                    },
                    {
                        label: "半人马战行者",
                        value: "npc_dota_hero_centaur",
                        heroAvatar: require("@/images/hero/power/centaur.png")
                    },
                    {
                        label: "混沌骑士",
                        value: "npc_dota_hero_chaos_knight",
                        heroAvatar: require("@/images/hero/power/chaos_knight.png")
                    },
                    {
                        label: "末日使者",
                        value: "npc_dota_hero_doom_bringer",
                        heroAvatar: require("@/images/hero/power/doom_bringer.png")
                    },
                    {
                        label: "龙骑士",
                        value: "npc_dota_hero_dragon_knight",
                        heroAvatar: require("@/images/hero/power/dragon knight.png")
                    },
                    {
                        label: "大地之灵",
                        value: "npc_dota_hero_earth_spirit",
                        heroAvatar: require("@/images/hero/power/earth_spirit.png")
                    },
                    {
                        label: "撼地者",
                        value: "npc_dota_hero_earthshaker",
                        heroAvatar: require("@/images/hero/power/earthshaker.png")
                    },
                    {
                        label: "上古巨神",
                        value: "npc_dota_hero_elder_titan",
                        heroAvatar: require("@/images/hero/power/elder_titan.png")
                    },
                    {
                        label: "哈斯卡",
                        value: "npc_dota_hero_huskar",
                        heroAvatar: require("@/images/hero/power/huskar.png")
                    },
                    {
                        label: "昆卡",
                        value: "npc_dota_hero_kunkka",
                        heroAvatar: require("@/images/hero/power/kunkka.png")
                    },
                    {
                        label: "军团指挥官",
                        value: "npc_dota_hero_legion_commander",
                        heroAvatar: require("@/images/hero/power/legion_commander.png")
                    },
                    {
                        label: "噬魂鬼",
                        value: "npc_dota_hero_life_stealer",
                        heroAvatar: require("@/images/hero/power/life_stealer.png")
                    },
                    {
                        label: "狼人",
                        value: "npc_dota_hero_lycan",
                        heroAvatar: require("@/images/hero/power/lycan.png")
                    },
                    {
                        label: "马格纳斯",
                        value: "npc_dota_hero_magnataur",
                        heroAvatar: require("@/images/hero/power/magnataur.png")
                    },
                    {
                        label: "玛尔斯",
                        value: "npc_dota_hero_mars",
                        heroAvatar: require("@/images/hero/power/mars.png")
                    },
                    {
                        label: "暗夜魔王",
                        value: "npc_dota_hero_night_stalker",
                        heroAvatar: require("@/images/hero/power/night_stalker.png")
                    },
                    {
                        label: "全能骑士",
                        value: "npc_dota_hero_omniknight",
                        heroAvatar: require("@/images/hero/power/omniknight.png")
                    },
                    {
                        label: "凤凰",
                        value: "npc_dota_hero_phoenix",
                        heroAvatar: require("@/images/hero/power/phoenix.png")
                    },
                    {
                        label: "帕吉",
                        value: "npc_dota_hero_pudge",
                        heroAvatar: require("@/images/hero/power/pudge.png")
                    },
                    {
                        label: "发条技师",
                        value: "npc_dota_hero_rattletrap",
                        heroAvatar: require("@/images/hero/power/magnataur.png")
                    },
                    {
                        label: "沙王",
                        value: "npc_dota_hero_sand_king",
                        heroAvatar: require("@/images/hero/power/sand_king.png")
                    },
                    {
                        label: "伐木机",
                        value: "npc_dota_hero_shredder",
                        heroAvatar: require("@/images/hero/power/shredder.png")
                    },
                    {
                        label: "冥魂大帝",
                        value: "npc_dota_hero_skeleton_king",
                        heroAvatar: require("@/images/hero/power/skeleton_king.png")
                    },
                    {
                        label: "斯拉达",
                        value: "npc_dota_hero_slardar",
                        heroAvatar: require("@/images/hero/power/slardar.png")
                    },
                    {
                        label: "电炎绝手",
                        value: "npc_dota_hero_snapfire",
                        heroAvatar: require("@/images/hero/power/snapfire.png")
                    },
                    {
                        label: "裂魂人",
                        value: "npc_dota_hero_spirit_breaker",
                        heroAvatar: require("@/images/hero/power/spirit_breaker.png")
                    },
                    { label: "斯温", value: "npc_dota_hero_sven", heroAvatar: require("@/images/hero/power/sven.png") },
                    {
                        label: "潮汐猎人",
                        value: "npc_dota_hero_tidehunter",
                        heroAvatar: require("@/images/hero/power/tidehunter.png")
                    },
                    { label: "小小", value: "npc_dota_hero_tiny", heroAvatar: require("@/images/hero/power/tiny.png") },
                    {
                        label: "树精卫士",
                        value: "npc_dota_hero_treant",
                        heroAvatar: require("@/images/hero/power/treant.png")
                    },
                    {
                        label: "巨牙海民",
                        value: "npc_dota_hero_tusk",
                        heroAvatar: require("@/images/hero/power/tusk.png")
                    },
                    {
                        label: "不朽尸王",
                        value: "npc_dota_hero_undying",
                        heroAvatar: require("@/images/hero/power/undying.png")
                    },
                    { label: "艾欧", value: "wisp", heroAvatar: require("@/images/hero/power/wisp.png") },  //暂无全名
                ]
            },
            {
                label: "敏捷",
                value: "agile",
                children: [
                    {
                        label: "敌法师",
                        value: "npc_dota_hero_antimage",
                        heroAvatar: require("@/images/hero/agile/antimage.png")
                    },
                    {
                        label: "天穹守望者",
                        value: "npc_dota_hero_arc_warden",
                        heroAvatar: require("@/images/hero/agile/Arc Warden.png")
                    },
                    {
                        label: "嗜血狂魔",
                        value: "npc_dota_hero_bloodseeker",
                        heroAvatar: require("@/images/hero/agile/Bloodseeker.png")
                    },
                    {
                        label: "赏金猎人",
                        value: "npc_dota_hero_bounty_hunter",
                        heroAvatar: require("@/images/hero/agile/bounty_hunter.png")
                    },
                    {
                        label: "育母蜘蛛",
                        value: "npc_dota_hero_broodmother",
                        heroAvatar: require("@/images/hero/agile/Broodmother.png")
                    },
                    {
                        label: "克里克兹",
                        value: "npc_dota_hero_clinkz",
                        heroAvatar: require("@/images/hero/agile/Clinkz.png")
                    },
                    {
                        label: "卓尔游侠",
                        value: "npc_dota_hero_drow_ranger",
                        heroAvatar: require("@/images/hero/agile/drow_ranger.png")
                    },
                    {
                        label: "灰烬之灵",
                        value: "npc_dota_hero_ember_spirit",
                        heroAvatar: require("@/images/hero/agile/ember_spirit.png")
                    },
                    {
                        label: "虚空假面",
                        value: "npc_dota_hero_faceless_void",
                        heroAvatar: require("@/images/hero/agile/Faceless Void.png")
                    },
                    {
                        label: "矮人直升机",
                        value: "npc_dota_hero_gyrocopter",
                        heroAvatar: require("@/images/hero/agile/gyrocopter.png")
                    },
                    { label: "森海飞霞", value: "hoodwink", heroAvatar: require("@/images/hero/agile/hoodwink.png") },  //暂无全名
                    {
                        label: "主宰",
                        value: "npc_dota_hero_juggernaut",
                        heroAvatar: require("@/images/hero/agile/juggernaut.png")
                    },
                    {
                        label: "德鲁伊",
                        value: "npc_dota_hero_lone_druid",
                        heroAvatar: require("@/images/hero/agile/lone_druid.png")
                    },
                    { label: "露娜", value: "npc_dota_hero_luna", heroAvatar: require("@/images/hero/agile/luna.png") },
                    {
                        label: "美杜莎",
                        value: "medusnpc_dota_hero_medusaa",
                        heroAvatar: require("@/images/hero/agile/Medusa.png")
                    },
                    {
                        label: "米波",
                        value: "npc_dota_hero_meepo",
                        heroAvatar: require("@/images/hero/agile/Meepo.png")
                    },
                    {
                        label: "米拉娜",
                        value: "npc_dota_hero_mirana",
                        heroAvatar: require("@/images/hero/agile/mirana.png")
                    },
                    {
                        label: "齐天大圣",
                        value: "npc_dota_hero_monkey_king",
                        heroAvatar: require("@/images/hero/agile/monkey_king.png")
                    },
                    {
                        label: "变体精灵",
                        value: "npc_dota_hero_morphling",
                        heroAvatar: require("@/images/hero/agile/morphling.png")
                    },
                    {
                        label: "娜迦海妖",
                        value: "npc_dota_hero_morphling",
                        heroAvatar: require("@/images/hero/agile/naga_siren.png")
                    },
                    {
                        label: "影魔",
                        value: "npc_dota_hero_nevermore",
                        heroAvatar: require("@/images/hero/agile/Nevermore.png")
                    },
                    {
                        label: "司夜刺客",
                        value: "npc_dota_hero_nyx_assassin",
                        heroAvatar: require("@/images/hero/agile/Nyx Assassin.png")
                    },
                    {
                        label: "石鳞剑士",
                        value: "npc_dota_hero_pangolier",
                        heroAvatar: require("@/images/hero/agile/pangolier.png")
                    },
                    {
                        label: "幻影刺客",
                        value: "npc_dota_hero_phantom_assassin",
                        heroAvatar: require("@/images/hero/agile/Phantom Assassin.png")
                    },
                    {
                        label: "幻影长矛手",
                        value: "npc_dota_hero_phantom_lancer",
                        heroAvatar: require("@/images/hero/agile/phantom_lancer.png")
                    },
                    {
                        label: "剃刀",
                        value: "npc_dota_hero_razor",
                        heroAvatar: require("@/images/hero/agile/Razor.png")
                    },
                    { label: "力丸", value: "npc_dota_hero_riki", heroAvatar: require("@/images/hero/agile/riki.png") },
                    {
                        label: "斯拉克",
                        value: "npc_dota_hero_slark",
                        heroAvatar: require("@/images/hero/agile/Slark.png")
                    },
                    {
                        label: "狙击手",
                        value: "npc_dota_hero_sniper",
                        heroAvatar: require("@/images/hero/agile/sniper.png")
                    },
                    {
                        label: "幽鬼",
                        value: "npc_dota_hero_spectre",
                        heroAvatar: require("@/images/hero/agile/Spectre.png")
                    },
                    {
                        label: "圣堂刺客",
                        value: "npc_dota_hero_templar_assassin",
                        heroAvatar: require("@/images/hero/agile/templar_assassin.png")
                    },
                    {
                        label: "恐怖利刃",
                        value: "npc_dota_hero_terrorblade",
                        heroAvatar: require("@/images/hero/agile/Terrorblade.png")
                    },
                    {
                        label: "巨魔战将",
                        value: "npc_dota_hero_troll_warlord",
                        heroAvatar: require("@/images/hero/agile/troll_warlord.png")
                    },
                    {
                        label: "熊战士",
                        value: "npc_dota_hero_ursa",
                        heroAvatar: require("@/images/hero/agile/ursa.png")
                    },
                    {
                        label: "复仇之魂",
                        value: "npc_dota_hero_vengefulspirit",
                        heroAvatar: require("@/images/hero/agile/vengefulspirit.png")
                    },
                    {
                        label: "剧毒术士",
                        value: "npc_dota_hero_venomancer",
                        heroAvatar: require("@/images/hero/agile/Venomancer.png")
                    },
                    {
                        label: "冥界亚龙",
                        value: "npc_dota_hero_viper",
                        heroAvatar: require("@/images/hero/agile/Viper.png")
                    },
                    {
                        label: "编织者",
                        value: "npc_dota_hero_weaver",
                        heroAvatar: require("@/images/hero/agile/Weaver.png")
                    },
                ]
            },
            {
                label: "智力",
                value: "intelligence",
                children: [
                    {
                        label: "远古冰魄",
                        value: "npc_dota_hero_ancient_apparition",
                        heroAvatar: require("@/images/hero/intelligence/Ancient Apparition.png")
                    },
                    {
                        label: "祸乱之源",
                        value: "npc_dota_hero_bane",
                        heroAvatar: require("@/images/hero/intelligence/Bane.png")
                    },
                    {
                        label: "蝙蝠骑士",
                        value: "npc_dota_hero_batrider",
                        heroAvatar: require("@/images/hero/intelligence/Batrider.png")
                    },
                    {
                        label: "陈",
                        value: "npc_dota_hero_chen",
                        heroAvatar: require("@/images/hero/intelligence/Chen.png")
                    },
                    {
                        label: "水晶室女",
                        value: "npc_dota_hero_crystal_maiden",
                        heroAvatar: require("@/images/hero/intelligence/Crystal Maiden.png")
                    },
                    {
                        label: "黑暗贤者",
                        value: "npc_dota_hero_dark_seer",
                        heroAvatar: require("@/images/hero/intelligence/Dark Seer.png")
                    },
                    {
                        label: "邪影芳灵",
                        value: "npc_dota_hero_dark_willow",
                        heroAvatar: require("@/images/hero/intelligence/Dark Willow.png")
                    },
                    {
                        label: "戴泽",
                        value: "npc_dota_hero_dazzle",
                        heroAvatar: require("@/images/hero/intelligence/Dazzle.png")
                    },
                    {
                        label: "死亡先知",
                        value: "npc_dota_hero_death_prophet",
                        heroAvatar: require("@/images/hero/intelligence/Death Prophet.png")
                    },
                    {
                        label: "干扰者",
                        value: "npc_dota_hero_disruptor",
                        heroAvatar: require("@/images/hero/intelligence/Disruptor.png")
                    },
                    {
                        label: "魅惑魔女",
                        value: "npc_dota_hero_enchantress",
                        heroAvatar: require("@/images/hero/intelligence/Enchantress.png")
                    },
                    {
                        label: "谜团",
                        value: "npc_dota_hero_enigma",
                        heroAvatar: require("@/images/hero/intelligence/Enigma.png")
                    },
                    {
                        label: "先知",
                        value: "npc_dota_hero_furion",
                        heroAvatar: require("@/images/hero/intelligence/Furion.png")
                    },
                    {
                        label: "天涯墨客",
                        value: "npc_dota_hero_grimstroke",
                        heroAvatar: require("@/images/hero/intelligence/grimstroke.png")
                    },
                    {
                        label: "祈求者",
                        value: "npc_dota_hero_invoker",
                        heroAvatar: require("@/images/hero/intelligence/Invoker.png")
                    },
                    {
                        label: "杰奇洛",
                        value: "npc_dota_hero_jakiro",
                        heroAvatar: require("@/images/hero/intelligence/Jakiro.png")
                    },
                    {
                        label: "光之守卫",
                        value: "npc_dota_hero_keeper_of_the_light",
                        heroAvatar: require("@/images/hero/intelligence/Keeper Of The Light.png")
                    },
                    {
                        label: "拉席客",
                        value: "npc_dota_hero_leshrac",
                        heroAvatar: require("@/images/hero/intelligence/Leshrac.png")
                    },
                    {
                        label: "巫妖",
                        value: "npc_dota_hero_lich",
                        heroAvatar: require("@/images/hero/intelligence/Lich.png")
                    },
                    {
                        label: "莉娜",
                        value: "npc_dota_hero_lina",
                        heroAvatar: require("@/images/hero/intelligence/Lina.png")
                    },
                    {
                        label: "莱恩",
                        value: "npc_dota_hero_lion",
                        heroAvatar: require("@/images/hero/intelligence/Lion.png")
                    },
                    {
                        label: "瘟疫法师",
                        value: "npc_dota_hero_necrolyte",
                        heroAvatar: require("@/images/hero/intelligence/Necrolyte.png")
                    },
                    {
                        label: "殁境神蚀者",
                        value: "npc_dota_hero_obsidian_destroyer",
                        heroAvatar: require("@/images/hero/intelligence/Obsidian Destroyer.png")
                    },
                    {
                        label: "食人魔魔法师",
                        value: "npc_dota_hero_ogre_magi",
                        heroAvatar: require("@/images/hero/intelligence/Ogre Magi.png")
                    },
                    {
                        label: "神谕者",
                        value: "npc_dota_hero_oracle",
                        heroAvatar: require("@/images/hero/intelligence/Oracle.png")
                    },
                    {
                        label: "帕克",
                        value: "npc_dota_hero_puck",
                        heroAvatar: require("@/images/hero/intelligence/puck.png")
                    },
                    {
                        label: "帕格纳",
                        value: "npc_dota_hero_pugna",
                        heroAvatar: require("@/images/hero/intelligence/Pugna.png")
                    },
                    {
                        label: "痛苦女王",
                        value: "npc_dota_hero_queenofpain",
                        heroAvatar: require("@/images/hero/intelligence/Queenofpain.png")
                    },
                    {
                        label: "拉比克",
                        value: "npc_dota_hero_rubick",
                        heroAvatar: require("@/images/hero/intelligence/Rubick.png")
                    },
                    {
                        label: "暗影恶魔",
                        value: "npc_dota_hero_shadow_demon",
                        heroAvatar: require("@/images/hero/intelligence/Shadow Demon.png")
                    },
                    {
                        label: "暗影萨满",
                        value: "npc_dota_hero_shadow_shaman",
                        heroAvatar: require("@/images/hero/intelligence/Shadow Shaman.png")
                    },
                    {
                        label: "沉默术士",
                        value: "npc_dota_hero_silencer",
                        heroAvatar: require("@/images/hero/intelligence/Silencer.png")
                    },
                    {
                        label: "天怒法师  ",
                        value: "npc_dota_hero_skywrath_mage",
                        heroAvatar: require("@/images/hero/intelligence/Skywrath Mage.png")
                    },
                    {
                        label: "风暴之灵",
                        value: "npc_dota_hero_storm_spirit",
                        heroAvatar: require("@/images/hero/intelligence/Storm Spirit.png")
                    },
                    {
                        label: "工程师",
                        value: "npc_dota_hero_techies",
                        heroAvatar: require("@/images/hero/intelligence/Techies.png")
                    },
                    {
                        label: "修补匠",
                        value: "npc_dota_hero_tinker",
                        heroAvatar: require("@/images/hero/intelligence/Tinker.png")
                    },
                    {
                        label: "维萨吉",
                        value: "npc_dota_hero_visage",
                        heroAvatar: require("@/images/hero/intelligence/Visage.png")
                    },
                    {
                        label: "虚无之灵",
                        value: "void_spirit",
                        heroAvatar: require("@/images/hero/intelligence/Void Spirit.png")
                    }, //暂无全名
                    {
                        label: "术士",
                        value: "npc_dota_hero_warlock",
                        heroAvatar: require("@/images/hero/intelligence/Warlock.png")
                    },
                    {
                        label: "风行者",
                        value: "npc_dota_hero_windrunner",
                        heroAvatar: require("@/images/hero/intelligence/Windrunner.png")
                    },
                    {
                        label: "寒冬飞龙",
                        value: "npc_dota_hero_winter_wyvern",
                        heroAvatar: require("@/images/hero/intelligence/Winter Wyvern.png")
                    },
                    {
                        label: "巫医",
                        value: "npc_dota_hero_witch_doctor",
                        heroAvatar: require("@/images/hero/intelligence/Witch Doctor.png")
                    },
                    {
                        label: "宙斯",
                        value: "npc_dota_hero_zuus",
                        heroAvatar: require("@/images/hero/intelligence/Zeus.png")
                    },
                ]
            }
        ]
    },
    
    {
        label: "品质",
        value: "quality",
        children: [
            { label: "不限", value: "unlimited" }, { label: "基础", value: "base" }, {
                label: "标准",
                value: "unique"
            }, { label: "铭刻", value: "strange" },
            { label: "纯正", value: "genuine" }, { label: "尊享", value: "exalted" }, {
                label: "英雄传世",
                value: "tournament"
            },
            { label: "融合", value: "infused" }, { label: "吉祥", value: "lucky" }, {
                label: "冥灵",
                value: "corrupted"
            },
            { label: "亲笔签名", value: "autographed" }, { label: "独特", value: " " }, {
                label: "凶煞",
                value: "haunted"
            },
            { label: "上古", value: "vintage" }, { label: "冻人", value: "frozen" },
            //  { label: "自制", value: "1" }, { label: "绝版", value: "12" }
        ]
    },
    {
        label: "稀有度",
        value: "rarity",
        children: [
            { label: "不限", value: "unlimited" },
            { label: "普通", color: "#fff", value: "Rarity_Common" },
            { label: "稀有", color: "#7C8FF5", value: "Rarity_Rare" },
            { label: "不朽", color: "#F2B166", value: "Rarity_Immortal" },
            { label: "传说", color: "#DC5EEA", value: "Rarity_Legendary" },
            { label: "至宝", color: "#9ACC4C", value: "Rarity_Arcana" },
            // { label: "赛季", color: "#3c3", value: "4" }, { label: "远古", color: "#E06B6A", value: "5" },
            { label: "罕见", color: "#82BDFF", value: "Rarity_Uncommon" },
            { label: "神话", color: "#A876F9", value: "Rarity_Mythical" },
        ]
    }
]

const tQualityObj = [
    {
        "label": "普通",
        "value": "normal",
        "color": "#b0c3d9"
    },
    {
        "label": "StatTrak™",
        "value": "strange",
        "color": "#CF6A32"
    },
    {
        "label": "★",
        "value": "unusual",
        "color": "#8650ac"
    },
    {
        "label": "★ StatTrak™",
        "value": "unusual_strange",
        "color": "#CF6A32"
    },
    {
        "label": "纪念品",
        "value": "tournament",
        "color": "#FFD700"
    }
]
const tRarityObj = [
    {
        "label": "卓越",
        "value": "Rarity_Mythical",
        "color": "#8847ff"
    },
    {
        "label": "隐秘",
        "value": "Rarity_Ancient_Weapon",
        "color": "#EB4B4B"
    },
    {
        "label": "保密",
        "value": "Rarity_Legendary_Weapon",
        "color": "#D32CE6"
    },
    {
        "label": "受限",
        "value": "Rarity_Mythical_Weapon",
        "color": "#8847FF"
    },
    {
        "label": "高级",
        "value": "Rarity_Rare",
        "color": "#4b69ff"
    },
    {
        "label": "非凡",
        "value": "Rarity_Ancient",
        "color": "#eb4b4b"
    },
    {
        "label": "军规级",
        "value": "Rarity_Rare_Weapon",
        "color": "#4B69FF"
    },
    {
        "label": "工业级",
        "value": "Rarity_Uncommon_Weapon",
        "color": "#5E98D9"
    },
    {
        "label": "消费级",
        "value": "Rarity_Common_Weapon",
        "color": "#B0C3D9"
    },
    {
        "label": "奇异",
        "value": "Rarity_Legendary",
        "color": "#d32ce6"
    },
    {
        "label": "普通级",
        "value": "Rarity_Common",
        "color": "#B0C3D9"
    },
    {
        "label": "违禁",
        "value": "Rarity_Contraband",
        "color": "#E4AE39"
    }
]
export {
    dota2, csgo, tQualityObj, tRarityObj
}





