import React, { $api, $tools, antdUI, useEffect, useRef, useState } from 'react';
import Sticker from '@/components/accessoriesList/accessoriesContainer/typePage/sticker'
import { Link } from "react-router-dom";
import './onShelf.scss'
import { connect } from 'react-redux';

const getPageType = (quality) => {
    if (quality === "strange" || quality === "unusual_strange") {
        return 2
    } else {
        return 1
    }
}

function OnShelfContainer(props) {
    let {
        setModalShow, changePrice, currentPage = 1, getDetailList,
        setCurrentPage, putSw, onShelfType, currentGame, setGame,
        type, set_onShelfData, userInfo, setSelectAllCur, handlePutSu, navData = {}
    } = props
    const [ onShelfData, setOnShelfData ] = useState(props.onShelfData),
        [ _OnShelfData, set_OnShelfData ] = useState([]),
        [ handlingFee, setHandlingFee ] = useState(50),
        [ merged, setMerged ] = useState(false),
        [ dataSource, setDataSource ] = useState([]),
        [ tableShow, setTableShow ] = useState(false),
        [ columns, setColumns ] = useState(''),
        [ putLoading, setPutLoading ] = useState(false);
    const { Sum, BASE_STEAM_IMG, checkResult, debounce, Less, obj2strUrl } = $tools;
    const handleAllPrice = () => {
        switch (onShelfType) {
            case 'onSale':
                onShelfData.forEach(_ => {
                    let _item = _;
                    _.sellingPrice = _item.refPrice ? parseFloat(Number(_item.refPrice * 1.01).toFixed(2)) : 0;
                    if (_.sellingPrice < 0) _.sellingPrice = 0
                });
                break;
            case 'intermediary':
                onShelfData.forEach(_ => {
                    let _item = _;
                    _.sellingPrice = Less(_item.price, 0.1).toFixed(2);
                    if (_.sellingPrice < 0) _.sellingPrice = 0
                });
                break;
            default:
                break;
        }
        
        setOnShelfData([ ...onShelfData ])
    }
    const handleTabData = (onShelfType, merged) => {
        if (merged) {
            let _mergedDataSource = []
            let img_name
            _OnShelfData.forEach((item, index) => {
                img_name = item.img_name || item?.CsgoItem?.img_name || navData?.img_name
                _mergedDataSource.push({
                    key: index,
                    item: (
                        <div style={ favoritesItemStyle }
                             className={ [ item.nums > 1 ? 'favorites-item put-tab-img' : 'favorites-item' ] }>
                            <Link
                                to={
                                    {
                                        pathname: '/marketPlace/accessoriesList',
                                        search: obj2strUrl({
                                            id: item.itemid,
                                            curGame: item.appid,
                                            curType: getPageType(item.tags ? item.tags.filter(v => v.category === 'Quality')[0].internal_name : item.CsgoItem ? item.CsgoItem.quality : item.quality),
                                        }, '?')
                                    }
                                }
                                target='_blank'
                            > <img style={ {
                                ...tabImg,
                                backgroundImage: `url(${ require('@/images/items/item_bg.png') })`,
                                position: 'relative',
                                zIndex: '1',
                                backgroundSize: 'cover',
                                width: '80px'
                            } } src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                                : BASE_STEAM_IMG + item.icon_url }
                                   alt={ item.market_name }/></Link>
                        </div>
                    ),
                    name: (
                        <div style={ { width: '240px' } } className="on-shelf-item-name">
                            <Link
                                to={
                                    {
                                        pathname: '/marketPlace/accessoriesList',
                                        search: obj2strUrl({
                                            id: item.itemid,
                                            curGame: item.appid,
                                            curType: getPageType(item.tags ? item.tags.filter(v => v.category === 'Quality')[0].internal_name : item.CsgoItem ? item.CsgoItem.quality : item.quality),
                                        }, '?')
                                    }
                                } target='_blank'
                            >
                                <antdUI.Popover placement="top"
                                                content={ <p
                                                    style={ { padding: "6px 8px" } }>{ item.market_name }</p> }>
                                    <p style={ { ...tabItemName, marginBottom: '0px' } }
                                       className="item-name one-text-flow">{ item.market_name }</p>
                                </antdUI.Popover>
                            </Link>
                            {
                                item.nums > 1 ?
                                    <>{ item._sticker ?
                                        <p style={ { ...floatval, color: "rgb(240, 147, 41)", fontSize: "13px" } }
                                           className="floatval">某些饰品含有印花</p> : '' }
                                        <p style={ floatval }
                                           className="floatval">×{ item.nums }</p></> : <> { item.market_item_detail && item.market_item_detail?.msg && item.market_item_detail?.msgfloatvalue !== '0' &&
                                        <> <p style={ floatval }
                                              className="floatval">{ item.market_item_detail?.msg.floatvalue ?
                                            `磨损:${ item.market_item_detail?.msg.floatvalue }` : "" }</p>
                                            { item.market_item_detail?.msg.stickers &&
                                                <Sticker type="onShelf"
                                                         stickers={ item.market_item_detail?.msg.stickers }/> }</>
                                    }</>
                            }
                        </div>
                    ),
                    refPrice: <div style={ { ...tabBox, marginLeft: 80 } }><h1
                        style={ tabH }>{ item.refPrice ? `¥${ item.refPrice }` : '暂无' }</h1></div>,
                    price: <div style={ tabBox }><h1 style={ tabH }>{ item.price ? `¥${ item.price }` : '暂无' }</h1>
                    </div>,
                    sellingPrice: <antdUI.Input style={ tabInput } min='0' type="number"
                                                value={ item.sellingPrice } onChange={ e => {
                        let value = e.target.value;
                        const sellingPrice = Number(value); // 向下取整保留一位小数
                        const cpOnShelfData = JSON.parse(JSON.stringify(onShelfData))
                        cpOnShelfData.forEach(el => {
                            if (el.market_hash_name === item.market_hash_name) {
                                el.sellingPrice = sellingPrice
                            }
                        })
                        setOnShelfData([ ...cpOnShelfData ])
                    } } onBlur={ e => {
                        let sellingPrice = Number(e.target.value).toFixed(2);
                        if (sellingPrice <= 0.01) {
                            antdUI.message.warning('饰品价格需要大于等于0.02 ！')
                            sellingPrice = 0
                        }
                        if (item.sellingPrice >= 1000000) {
                            antdUI.message.warning('饰品价格过大，请重新设置！')
                            sellingPrice = 0
                        }
                        const cpOnShelfData = JSON.parse(JSON.stringify(onShelfData))
                        cpOnShelfData.forEach(el => {
                            if (el.market_hash_name === item.market_hash_name) {
                                el.sellingPrice = sellingPrice
                            }
                        })
                        setOnShelfData([ ...cpOnShelfData ])
                    } } placeholder="￥买家支付金额"/>,
                    description: (
                        <antdUI.Input.TextArea style={ { ...tabInput, height: '45px' } } value={ item.onShelfDesc }
                                               onChange={ e => {
                                                   item.onShelfDesc = e.target.value;
                                                   setOnShelfData([ ...onShelfData ])
                                               } } placeholder="添加描述"/>
                    )
                })
            })
            setDataSource(_mergedDataSource)
        } else {
            let _dataSource = []
            switch (onShelfType) {
                case 'onSale':
                    // if (type === 'changePrice') { setColumns(columns_on.filter(_ => _.key !== 'price')) } else { setColumns(columns_on) }
                    setColumns(columns_on)
                    let img_name
                    onShelfData.forEach((item, index) => {
                        img_name = item.img_name || item?.CsgoItem?.img_name || navData?.img_name
                        _dataSource.push({
                            key: index,
                            item: (
                                <div style={ favoritesItemStyle } className="favorites-item">
                                    <Link
                                        to={
                                            {
                                                pathname: '/marketPlace/accessoriesList',
                                                search: obj2strUrl({
                                                    id: item.itemid,
                                                    curGame: item.appid,
                                                    curType: getPageType(item.tags ? item.tags.filter(v => v.category === 'Quality')[0].internal_name : item.CsgoItem ? item.CsgoItem.quality : item.quality),
                                                }, '?')
                                            }
                                        }
                                        target='_blank'
                                    > <img style={ {
                                        ...tabImg,
                                        backgroundImage: `url(${ require('@/images/items/item_bg.png') })`,
                                        backgroundSize: 'cover',
                                        width: '80px'
                                    } }
                                           src={ img_name ? `${ React.StaticBaseUrl }/market-csgo/${ img_name }.png`
                                               : BASE_STEAM_IMG + item.icon_url }
                                           alt={ item.market_name }/>
                                    
                                    
                                    </Link>
                                </div>
                            ),
                            name: (
                                <div style={ { width: '240px' } } className="on-shelf-item-name">
                                    <Link
                                        to={
                                            {
                                                pathname: '/marketPlace/accessoriesList',
                                                search: obj2strUrl({
                                                    id: item.itemid,
                                                    curGame: item.appid,
                                                    curType: getPageType(item.tags ? item.tags.filter(v => v.category === 'Quality')[0].internal_name : item.CsgoItem ? item.CsgoItem.quality : item.quality),
                                                }, '?')
                                            }
                                        }
                                        target='_blank'
                                    >
                                        <antdUI.Popover placement="top"
                                                        content={ <p
                                                            style={ { padding: "6px 8px" } }>{ item.market_name }</p> }>
                                            <p style={ { ...tabItemName, marginBottom: '0px' } }
                                               className="item-name one-text-flow">{ item.market_name }</p>
                                        </antdUI.Popover>
                                    </Link>
                                    { item.market_item_detail && item.market_item_detail?.msg && item.market_item_detail?.msgfloatvalue !== '0' &&
                                        <p style={ floatval }
                                           className="floatval">{ item.market_item_detail?.msg.floatvalue ?
                                            `磨损:${ item.market_item_detail?.msg.floatvalue }` : "" }</p> }
                                    { item.market_item_detail?.msg.stickers &&
                                        <Sticker type="onShelf" stickers={ item.market_item_detail?.msg.stickers }/> }
                                </div>
                            ),
                            refPrice: <div style={ { ...tabBox, marginLeft: 80 } }><h1
                                style={ tabH }>{ item.refPrice ? `¥${ item.refPrice }` : '暂无' }</h1></div>,
                            price: <div style={ tabBox }><h1
                                style={ tabH }>{ item.price ? `¥${ item.price }` : '暂无' }</h1></div>,
                            sellingPrice: <antdUI.Input style={ tabInput } min='0' type="number"
                                                        value={ item.sellingPrice } onChange={ e => {
                                let value = e.target.value;
                                item.sellingPrice = Number(value)
                                setOnShelfData([ ...onShelfData ])
                            } } onBlur={ e => {
                                item.sellingPrice = Number(e.target.value).toFixed(2)
                                if (item.sellingPrice <= 0.01) {
                                    antdUI.message.warning('饰品价格需要大于等于0.02 ！')
                                    item.sellingPrice = 0
                                }
                                if (item.sellingPrice >= 1000000) {
                                    antdUI.message.warning('饰品价格过大，请重新设置！')
                                    item.sellingPrice = 0
                                }
                                setOnShelfData([ ...onShelfData ])
                            } } placeholder="￥买家支付金额"/>,
                            description: (
                                <antdUI.Input.TextArea style={ { ...tabInput, height: '45px' } }
                                                       value={ item.onShelfDesc }
                                                       onChange={ e => {
                                                           item.onShelfDesc = e.target.value;
                                                           setOnShelfData([ ...onShelfData ])
                                                       } } placeholder="添加描述"/>
                            )
                        })
                    })
                    break;
                case 'intermediary':
                    $api.user.getIntermediaryFees().then(res => {
                        checkResult(res) && setHandlingFee(res.data.amount)
                    });
                    setColumns(columns_in)
                    onShelfData.forEach((item, index) => {
                        _dataSource.push({
                            key: index,
                            item: (
                                <div style={ favoritesItemStyle } className="favorites-item">
                                    <img style={ {
                                        ...tabImg,
                                        backgroundImage: `url(${ require('@/images/items/item_bg.png') })`,
                                        width: '75px'
                                    } } src={ BASE_STEAM_IMG + item.icon_url } alt=""/>
                                </div>
                            ),
                            name: (
                                <div style={ { width: '240px' } } className="on-shelf-item-name">
                                    <Link
                                        to={
                                            {
                                                pathname: '/marketPlace/accessoriesList',
                                                search: obj2strUrl({
                                                    id: item.itemid,
                                                    curGame: '730',
                                                    curType: getPageType(item.tags ? item.tags.filter(v => v.category === 'Quality')[0].internal_name : item.CsgoItem ? item.CsgoItem.quality : item.quality),
                                                }, '?')
                                            }
                                        }
                                        target='_blank'
                                    ><p style={ { ...tabItemName, marginBottom: '0px' } }
                                        className="item-name one-text-flow">{ item.market_name }</p></Link>
                                    { item.market_item_detail && item.market_item_detail?.msg && item.market_item_detail?.msgfloatvalue !== '0' &&
                                        <p style={ floatval }
                                           className="floatval">{ item.market_item_detail?.msg.floatvalue ?
                                            `磨损:${ item.market_item_detail?.msg.floatvalue }` : "" }</p> }
                                    { item.market_item_detail?.msg.stickers &&
                                        <Sticker type="onShelf" stickers={ item.market_item_detail?.msg.stickers }/> }
                                </div>
                                // <div style={{ width: '240px' }} className="on-shelf-item-name">
                                //   <p style={tabItemName} className="item-name one-text-flow">{item.market_name}</p>
                                //   {item.market_item_detail && item.market_item_detail?.msg && item.market_item_detail?.msgfloatvalue !== '0' &&
                                //     <p style={floatval} className="floatval">磨损:{item.market_item_detail?.msg.floatvalue}</p>}
                                // </div>
                            ),
                            price: <div style={ tabBox }><h1
                                style={ tabH }>{ item.price ? `¥${ item.price }` : '暂无' }</h1></div>,
                            sellingPrice: <antdUI.Input style={ tabInput } min='0' type="number"
                                                        value={ item.sellingPrice } onChange={ e => {
                                let value = e.target.value;
                                item.sellingPrice = Number(value)
                                setOnShelfData([ ...onShelfData ])
                            } } onBlur={ e => {
                                item.sellingPrice = Number(e.target.value).toFixed(1)
                                setOnShelfData([ ...onShelfData ])
                            } } placeholder="￥买家支付金额"/>,
                            description: (
                                <antdUI.Input.TextArea style={ { ...tabInput, height: '45px' } }
                                                       value={ item.onShelfDesc }
                                                       onChange={ e => {
                                                           item.onShelfDesc = e.target.value;
                                                           setOnShelfData([ ...onShelfData ])
                                                       } } placeholder="添加描述"/>
                            )
                        })
                    })
                    break;
                default:
                    break;
            }
            setDataSource(_dataSource)
        }
    }
    useEffect(() => {
        handleTabData(onShelfType, merged)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ onShelfType ])
    
    useEffect(() => {
        dataSource[0] && setTableShow(true)
    }, [ dataSource ])
    
    useEffect(() => {
        let data = []
        onShelfData.forEach((item) => {
            if (data.filter(_ => _.market_hash_name === item.market_hash_name)[0]) {
                data.forEach(_ => {
                    if (_.market_hash_name === item.market_hash_name) {
                        _.nums += 1;
                        if (item?.market_item_detail?.msg?.stickers?.[0]) _._sticker = 1
                    }
                })
            } else {
                data.push({ nums: 1, ...item, _sticker: item?.market_item_detail?.msg?.stickers?.[0] })
            }
        })
        set_OnShelfData(data);
        handleTabData(onShelfType, merged)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ onShelfData ])
    
    useEffect(() => {
        setOnShelfData(props.onShelfData)
    }, [ props.onShelfData ])
    
    useEffect(() => {
        handleTabData(onShelfType, merged)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ _OnShelfData ])
    
    useEffect(() => {
        if (merged) {
            let cpOnShelfData = JSON.parse(JSON.stringify(onShelfData))
            cpOnShelfData.forEach(el => {
                el.sellingPrice = 0
            })
            setOnShelfData([ ...cpOnShelfData ])
        }
        handleTabData(onShelfType, merged)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ merged ])
    
    const totalPrice = () => {
        let _len = onShelfData.length
        if (_len >= 2) {
            return onShelfData.reduce((a, b) => {
                typeof a === 'object' && (a = a.sellingPrice)
                return Sum(a, b.sellingPrice)
            })
        } else if (_len === 1) {
            return onShelfData[0].sellingPrice
        } else {
            return 0
        }
    }
    
    const putEnd = (itemData) => {
        if (type === 'changePrice') {
            set_onShelfData([])
            antdUI.message.success('改价成功')
            setSelectAllCur && setSelectAllCur(false)
        } else {
            antdUI.message.success('上架成功')
        }
        setTimeout(() => {
            setModalShow(false)
            if (changePrice) return changePrice.current.getData(currentPage)
            if (setGame) {
                setGame(0);
                setTimeout(() => setGame(currentGame));
            }
            handlePutSu && handlePutSu(itemData);
            
            getDetailList && getDetailList();
            
            setCurrentPage && setCurrentPage();
        }, 300);
    }
    
    const intermediaryPut = async () => {
        let data = { datas: [] }
        if (! onShelfData[0]) {
            antdUI.message.error('请先勾选物品在进行上架')
            return
        }
        onShelfData.forEach((item) => {
            data.datas.push({
                instanceid: item.instanceid,
                market_name: item.market_name,
                market_item_detail: item.market_item_detail ? JSON.stringify(item.market_item_detail) : JSON.stringify({}),
                sellingPrice: item.sellingPrice,
                onShelfDesc: item?.onShelfDesc,
                assetid: item.assetid,
                actions: item.actions?.[0] ? item.actions[0].link : '',
                classid: item.classid,
                icon_url: item.icon_url,
                appid: currentGame
            })
        })
        data.itemTotal = onShelfData.length;
        data.appid = currentGame;
        data.handlingFee = handlingFee;
        data.totalPrice = totalPrice() // 买家实付金额 用于自售
        setPutLoading(true)
        if (! putLoading) {
            let result = await $api.putShelf.intermediaryListing(data);
            checkResult(result) && putEnd()
        }
    }
    const onShelfPut = async () => {
        let itemData = []
        let result = {};
        if (type === 'changePrice') {
            for (const item of onShelfData) {
                itemData.push({
                    orderNumber: item.orderNumber,
                    sellingPrice: item.sellingPrice,
                    onShelfDesc: item?.onShelfDesc,
                })
            }
            result = await $api.putShelf.changeOnSalePrice({ itemData, appid: currentGame })
            checkResult(result) && putEnd()
            return
        }
        switch (Number(currentGame)) {
            case 730:
                let market_detail;
                for (const item of onShelfData) {
                    market_detail = item.market_item_detail ? item.market_item_detail?.msg : {}
                    itemData.push({
                        appid: currentGame,
                        market_hash_name: item.market_hash_name,
                        assetid: item.assetid,
                        classid: item.classid,
                        actions: item.actions?.[0] ? item.actions[0].link : '',
                        instanceid: item.instanceid,
                        market_name: item.market_name,
                        name: item.name,
                        icon_url: item.icon_url,
                        descriptions: JSON.stringify(item.descriptions),
                        floatvalue: market_detail.floatvalue,
                        stickers: market_detail.stickers ? JSON.stringify(market_detail.stickers) : '',
                        paintindex: market_detail.paintindex,
                        paintseed: market_detail.paintseed,
                        customname: item.fraudwarnings?.[0] || '',
                        sellingPrice: item.sellingPrice,
                        onShelfDesc: item?.onShelfDesc,
                    })
                }
                
                break;
            case 570:
                for (const item of onShelfData) {
                    itemData.push({
                        appid: currentGame,
                        market_hash_name: item.market_hash_name,
                        assetid: item.assetid,
                        classid: item.classid,
                        instanceid: item.instanceid,
                        market_name: item.market_name,
                        name: item.name,
                        icon_url: item.icon_url,
                        descriptions: JSON.stringify(item.descriptions),
                        sellingPrice: item.sellingPrice,
                        onShelfDesc: item?.onShelfDesc,
                    })
                }
                
                break;
            default:
                break;
        }
        setPutLoading(true)
        if (! putLoading) {
            result = await $api.putShelf.putOnSaleItems({ itemData, appid: currentGame })
            if (checkResult(result)) {
                putEnd(itemData)
            } else {
                setPutLoading(false)
            }
        }
    }
    const onShelfItem = async () => {
        if (! userInfo.steamid || ! userInfo.uid) return antdUI.message.warning('请先完善绑手机绑定和Steam绑定！')
        // if (!userInfo.real_name_status) { antdUI.message.warning('请先完成实名认证！'); return; }
        if (! userInfo.steam?.transactionLink) return antdUI.message.warning('请先绑定 steam 交易链接！');
        if (! userInfo.steam?.apiKey) return antdUI.message.warning('请先绑定 ApiKey!');
        if (putSw) return antdUI.message.warning('请将物品解析完毕');
        let onShelfNums = 0,
            onShelf = false;
        onShelfData.forEach(i => { Number(i.sellingPrice) && onShelfNums++ })
        onShelfNums === onShelfData.length && (onShelf = true);
        if (onShelf) { // 上架
            if (onShelfData.length) {
                if (onShelfType === 'intermediary') {
                    await intermediaryPut()
                } else if (onShelfType === 'onSale') {
                    setPutLoading(true)
                    await onShelfPut()
                    setPutLoading(false)
                }
            } else {
                antdUI.message.warning('请勾选要上架的物品')
            }
        } else {
            antdUI.message.warning('请输入正确金额')
        }
    }
    
    const deHandPrice = useRef(debounce(handleAllPrice, 1000, true)).current
    
    function putBtn() {
        const deOnShelfItem = debounce(onShelfItem, 2000, true)
        return (<antdUI.Button className="on-shelf-btn" onClick={ deOnShelfItem } loading={ putLoading }>
            { type === 'changePrice' ? '改价' : '上架' }
        </antdUI.Button>)
    }
    
    function renderTable() {
        return (<antdUI.Table pagination={ false } dataSource={ dataSource } columns={ columns }
                              scroll={ { y: 400 } }></antdUI.Table>)
    }
    
    return (
        <div className="on-shelf-container">
            <div className="title-box">
                <h1 className="title">{ type === 'changePrice' ? '饰品改价' : '上架饰品' }
                </h1>
                <div className="success-price">
                    <antdUI.Popconfirm icon={ false } title={ (<p
                        style={ { width: '200px', fontSize: '14px' } }>
                        一键定价为自动定价功能，自动定价到当前饰品市场价最低价 -0.1 ￥
                    </p>) } placement="bottom">
                        <i></i>
                    </antdUI.Popconfirm>
                    <div className="btn" onClick={ deHandPrice }>一键定价</div>
                </div>
            </div>
            {/* locale={{ emptyText: '暂无数据' }} */ }
            <p style={ { color: '#fc9a2b', textAlign: 'center' } }>{
                userInfo.isOnline === 1 ? '' :
                    '你的店铺目前是离线状态，此时买家在饰品市场看不到你的物品。请在上架或改价之后，及时将你的店铺状态改为在线。'
            }</p>
            <div className="on-shelf-item-container">
                { tableShow ? dataSource[0] ? renderTable() : "" : "" }
            </div>
            
            <div className="on-shelf-bottom-bar">
                <div className="income">
                    预计收入：
                    <p className="all-price">{ totalPrice() || 0 }元
                        <span className="item-num">/共{ onShelfData.length }件</span>
                    </p>
                </div>
                
                { onShelfType === 'onSale' &&
                    ! <p className="prompt">当前信誉积分为{ userInfo.reputationPoints }分，
                        { userInfo.reputationPoints < 70 ?
                            <>查看 <a href="/profile/rate" target="_blank"
                                      style={ { fontWeight: "800", textDecoration: "underline" } }>当前手续费政策</a></>
                            : <>
                                享受<a href="/profile/rate" target="_blank"
                                       style={ { fontWeight: "800", textDecoration: "underline" } }>对应手续费政策</a>
                            </> }
                    
                    </p> }
                
                { userInfo.isOnline === 0 ?
                    <p className="prompt">当前店铺为离线状态，饰品不会在市场展示，买家也无法购买这些饰品</p> : '' }
                { putSw ?
                    <div style={ { background: '#fff', color: "#333" } } className="on-shelf-btn">物品正在解析...</div>
                    : <>
                        { onShelfType === 'onSale' && onShelfData.length > 1 && <div className="merged-items">
                            <antdUI.Checkbox checked={ merged }
                                             onChange={ e => setMerged(e.target.checked) }>合并相同饰品
                            </antdUI.Checkbox>
                        </div> }
                        { putBtn() }
                    </>
                }
            </div>
        </div>
    )
}


export default connect(
    function mapStateToProps(state) {
        return {
            userInfo: state.user.userInfo
        }
    }
)(OnShelfContainer);
let tabBox = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'rgb(240, 147, 41)'
    },
    tabH = {
        fontSize: '18px',
        fontWeight: 600,
        color: 'rgb(240, 147, 41)',
    },
    favoritesItemStyle = {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: 'cover',
        transform: 'scale(1.1)',
        
    },
    tabImg = {
        width: '65px'
    },
    tabItemName = {
        maxWidth: '180px',
        fontSize: '14px',
        color: '#222222',
        marginBottom: '10px'
    },
    floatval = {
        maxWidth: '180px',
        fontSize: '12px',
        color: '#999999'
    },
    tabInput = {
        width: '100%',
        height: '32px',
        border: ' 1px solid #CCCCCC',
        borderRadius: '4px',
        fontSize: '12px',
        margin: '0 auto'
    };
let titleStyle = { textAlign: 'center', fontWeight: 900, fontSize: '14px' };
let columns_on = [
        {
            title: <p style={ titleStyle }>饰品图片</p>,
            dataIndex: 'item',
            key: 'item',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>饰品详情</p>,
            dataIndex: 'name',
            key: 'name',
            render: text => text
        },
        {
            title: <p style={ { ...titleStyle, marginLeft: 92, width: 42 } }>参考价</p>,
            dataIndex: 'refPrice',
            key: 'refPrice',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>市场价</p>,
            dataIndex: 'price',
            key: 'price',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>出售金额</p>,
            dataIndex: 'sellingPrice',
            key: 'sellingPrice',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>描述</p>,
            dataIndex: 'description',
            key: 'description',
            render: text => text
        }
    ],
    columns_in = [
        {
            title: <p style={ titleStyle }>饰品图片</p>,
            dataIndex: 'item',
            key: 'item',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>饰品详情</p>,
            dataIndex: 'name',
            key: 'name',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>市场价</p>,
            dataIndex: 'price',
            key: 'price',
            render: text => text
        },
        {
            title: <p style={ titleStyle }>售价</p>,
            dataIndex: 'sellingPrice',
            key: 'sellingPrice',
            render: text => text
        }, {
            title: <p style={ titleStyle }>描述</p>,
            dataIndex: 'description',
            key: 'description',
            render: text => text
        }
    ];
